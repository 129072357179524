/* ============================================================================
Algolia Search functionality
============================================================================ */

import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import { history } from "instantsearch.js/es/lib/routers";
import { singleIndex } from "instantsearch.js/es/lib/stateMappings";
import {
  searchBox,
  stats,
  hits,
  configure,
  pagination,
  menuSelect,
} from "instantsearch.js/es/widgets";
import easydropdown from "easydropdown";

jQuery(($) => {



  /* ============== Remove Special Characters from input/any value =============*/
  function removeSpecialCharacters(input) { 
    let regex = /[&\/\\#,+()$~%.'":*?<>{}]/g; 
    let result = input.replace(regex, '');
    return result;
  }



  /* ============== Add search category tab to search page =============*/

  // Get query string and prepend tab like link to redirect to separate content with fetched query string as a searching parameter

  let params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  let urlValue = params.query;

  let docTitle = "";
  let docLink = "";

  docTitle = '<div class="results-header">Search Results: Documentation</div>';
  docLink = '<div class="scroller-tabs-nav" ><ul class="tabs-nav" >';
  docLink += '<li class="docs tab-active">Documentation</li>';
  docLink +=
    '<a href="https://community.exabeam.com/s/search?source=knowledge&term=' +
    removeSpecialCharacters(urlValue)  +
    ' "><li class="knowledge" >Community Knowledge Base</li></a>';
  docLink +=
    '<a href="https://community.exabeam.com/s/search?source=discussions&term=' +
    removeSpecialCharacters(urlValue)  +
    ' ""><li class="discussions" >Discussions</li></a>';
  docLink +=
    '<a href="https://community.exabeam.com/s/search?source=cases&term=' +
    removeSpecialCharacters(urlValue )+
    ' ""><li class="cases" >Support Cases</li></a>';
  docLink += "</ul></div>";

  $(".search-success").prepend(docTitle + docLink + "<br><br>");

  // Initialize Algolia only on the search results page.
  if ($("body").is(".search")) {
    const searchClient = algoliasearch(
      window.rsApp.algoliaAppId,
      window.rsApp.algoliaApiKey
    );

    /* =================================================
        Configure the instantsearch library.
        =================================================== */
    const search = instantsearch({
      searchClient,
      indexName: window.rsApp.algoliaIndexName,

      routing: {
        router: history(),
        stateMapping: singleIndex(window.rsApp.algoliaIndexName),
      },
      
      searchFunction(helper) {
        // Hide search results when search terms are empty or whitespace.
        const empty = helper.state.query.trim() === "";
        $(".search-no-terms").toggleClass("hidden", !empty);
        $(".search-success").toggleClass("hidden", empty);

        helper.search();
      },
    });
    
    /* =================================================
        Add search widgets.
    =================================================== */
    search.addWidgets([
      configure({
        hitsPerPage: 5,
      }),

      searchBox({
        container: "#searchbox",
        placeholder: window.rsApp.searchPlaceholder,
        showReset: false,
        showLoadingIndicator: false,
        cssClasses: {
          input: "input-lg",
          form: "search-input-wrap",
        },
        templates: {
          submit: window.rsApp.searchIcon,
        },
      }),

      stats({
        container: "#stats",
        templates: {
          text(data) {
            let result = "";

            if (data.hasManyResults) {
              const firstResult = data.hitsPerPage * data.page + 1;
              const lastResult = Math.min(
                firstResult + data.hitsPerPage - 1,
                data.nbHits
              );

              result += window.rsApp.searchManyResults
                .replace("{{first}}", firstResult)
                .replace("{{last}}", lastResult)
                .replace("{{total}}", data.nbHits);
            } else if (data.hasOneResult) {
              result += window.rsApp.searchOneResult;
            } else {
              result += window.rsApp.searchNoResults;
            }

            /// Sanitize user input in result summary (stats) ///
            const sanitizedQuery = $("<textarea />")
              .text(data.query)
              .html();
            result += window.rsApp.searchQuery.replace(
              "{{query}}",
              sanitizedQuery
            );

            return result;
          },
        },
      }),

      hits({
        container: "#hits",
        templates: {
          empty: "",
          item(item) {
            const title = instantsearch.highlight({
              attribute: "title",
              hit: item,
            });
            const body = instantsearch.highlight({
              attribute: "body",
              hit: item,
            });
            let product = "";
            let version = "";
            let publication = "";

            // Show product badge if not filtered by product.
            if (
              item.product.length &&
              !search.helper.state.hierarchicalFacetsRefinements.product.length
            ) {
              product = `<span class="badge badge-product">${item.product[0]}</span>`;
            }

            // Show version badge if not filtered by version.
            if ( typeof item.version !== 'undefined' && item.version.length && !search.helper.state.hierarchicalFacetsRefinements.version.length ) {
              version = item.version[0].split(" - ")[1];
              version = `<span class="badge badge-version">${version}</span>`;
            }

            // If in a Release Notes publication, tag this entry as such.
            if (item.url.indexOf("release-notes") !== -1) {
              publication = `<span class="badge badge-publication">${rsApp.releaseNotes}</span>`;
            }

            return `<article>
                            <p class="small"><a href="${item.url}"><strong>${title}</strong></a></p>
                            <p class="small">${body}</p>
                            <p>${product}${version}${publication}</p>
                        </article>`;
          },
        },
      }),

      pagination({
        container: "#pagination",
        showFirst: false,
        showLast: false,
        padding: 4,
        scrollTo: "#searchbox",
        templates: {
          previous: `<span class="glyphicon glyphicon-circle-arrow-left"></span>&nbsp;${window.rsApp.paginationPrev}`,
          next: `${window.rsApp.paginationNext}&nbsp;<span class="glyphicon glyphicon-circle-arrow-right"></span>`,
        },
      }),

      menuSelect({
        container: "#filter-product",
        attribute: "product",
        templates: {
          defaultOption: window.rsApp.allProducts,
          item: "{{label}}",
        },
      }),

      menuSelect({
        container: "#filter-version",
        attribute: "version",
        sortBy: ["name:desc"],
        templates: {
          defaultOption: window.rsApp.allVersions,
          item: "{{label}}",
        },
        transformItems: (items) =>
          items.map((item) => {
            item.label = item.label.split(" - ")[1];
            return item;
          }),
      }),
    ]);

    /* =================================================
        Improved UX during interaction.
        =================================================== */
    // Attach custom render function
    search.on("render", () => {
      // If no Product filter has been applied, disable Version dropdown and
      // clear Version filter.
      const disableVersions = !$("#filter-product select").val();
      const $versionFilter = $("#filter-version select");

      $versionFilter.prop("disabled", disableVersions);
      // A little cumbersome to change search attributes dynamically; this
      // clears the version filter only if a version facet was specified, and
      // refreshes the search (need to check the second condition to prevent
      // infinite re-render loop).
      if (
        disableVersions &&
        search.helper.state.hierarchicalFacetsRefinements.version.length
      ) {
        search.helper.state.hierarchicalFacetsRefinements.version = [];
        search.refresh();
      }

      // Ensure Easydropdown is enabled for selects.
      easydropdown.all({
        behavior: {
          liveUpdates: true,
        },
      });
    });

    /* =================================================
        Error handling.
        =================================================== */
    search.on("error", ({ error }) => {
      console.error(error.message);
    });

    search.start();
  }



});
