/* =============================================================================
Print-related functionality
============================================================================= */

jQuery(($) => {
  const $print = $(
    '<button class=" btn-print fa fa-print" aria-hidden="true"">'
  );
  // const pdf = $(".btn-pdf");

  $print.on("click", () => window.print());

  $(".publication-links")
    .first()
    .append($print);

  // $(".publication-links")
  //   .first()
  //   .append(pdf);

  // jQuery("a.btn-pdf")
  //   .detach()
  //   .appendTo(".publication-links");
});
