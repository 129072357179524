/* =============================================================================
Table of Contents preparation & interaction
============================================================================= */

jQuery(($) => {
  // console.log("Loaded from Custom toc");
  /* =================================================
    Inject TOC title/toggler into sticky TOC on mobile
    =================================================== */
  const $tocContainer = $(".toc-container");
  if ($tocContainer.length) {
    const $title = $(
      '<a href="#" role="button" aria-expanded="false" class="toc-title-mobile"></a>'
    );
    $title.text(window.rsApp.tocTitle);
    $tocContainer.prepend($title);

    // Enable expansion/collapse of mobile TOC.
    $title.on("click", function() {
      $tocContainer.toggleClass("toc-container--expanded");
      $(this).attr(
        "aria-expanded",
        $tocContainer.hasClass("toc-container--expanded")
      );
      return false;
    });

    $("#toc-placeholder").on("click", function(e) {
      if ($(this).is(e.target)) {
        $title.click();
      }
    });

    // Close TOC on mobile when in-page anchor link clicked.
    $("ul.toc")
      .find('a[href*="#"]')
      .on("click", function() {
        // If going to a link on the same page and the TOC is expanded, collapse it.
        if (
          this.pathname === window.location.pathname &&
          $tocContainer.hasClass("toc-container--expanded")
        ) {
          $title.trigger("click");
        }
      });
  }

  /* =================================================
    Add additional active-trail classes to Paligo's dynamic TOC.
    =================================================== */
  // Process markup on click event (not needed until there's interaction anyway)
  // since another script is the one responsible for adding the 'active' class
  // later in the document ready pipeline.
  $(document).on("mousedown", "ul.toc:not(.rs-processed) .topic-link", () => {
    $("ul.toc .topic-link.active")
      .parents("li")
      .addClass("active-trail");

    // Mark TOC as processed so we don't run this handler again.
    $("ul.toc").addClass("rs-processed");
  });

  /* =================================================
    Highlight TOC item on scroll to section
    =================================================== */

  // Only do TOC highlighting if there's a TOC.
  if (!$tocContainer.length) {
    return;
  }

  /* IntersectionObserver setup
    =================================================== */

  // Stores the <section> elements being monitored for intersection.
  const observedElements = [];
  // A subset of the above array tracking which are currently intersecting.
  const intersecting = new Set();

  /**
   * Responds to intersection changes by highlighting active TOC item when
   * corresponding section scrolls into view.
   *
   * @param {Array}   entries Intersection entries being monitored.
   */
  const intersectionCallback = (entries) => {
    let active;
    const $toc = $("ul.toc");
    // Remove "active" class on all TOC links to start with.
    $toc.find("li li a").removeClass("active");

    for (const entry of entries) {
      // Keep track of all elements intersecting the viewport.
      if (entry.isIntersecting) {
        intersecting.add(entry.target);
      } else {
        intersecting.delete(entry.target);
      }
    }

    // Attempt to highlight each matching section.
    intersecting.forEach((el) => {
      const $candidate = $toc.find(`a[href$="#${el.id}"]`);
      const $prev = $candidate
        .parent()
        .prevAll()
        .children("a");
      const $next = $candidate
        .parent()
        .nextAll()
        .children("a");

      // Only highlight candidate if previous siblings haven't already been.
      if (!$prev.length || !$prev.is(".active")) {
        $candidate.addClass("active");

        // If one of next siblings has also been marked active, undo that.
        if ($next.length && $next.is(".active")) {
          $next.removeClass("active");
        }
      }
    });
  };

  const verticalOffset = parseInt($("html").css("margin-top")) + 150;
  const observer = new window.IntersectionObserver(intersectionCallback, {
    rootMargin: `-${verticalOffset}px 0px 0px`,
  });

  /* Populate observed elements
    =================================================== */
  $("#topic-content > section section[id]").each(function() {
    observedElements.push(this);
  });

  /* Set up the intersection observer
    =================================================== */
  // Clear out old elements and add new ones.
  observer.disconnect();
  observedElements.forEach((el) => observer.observe(el));
});
