/* =============================================================================
Globally available functions
============================================================================= */

jQuery(($) => {
  var base_url = window.location.origin;
  var host = window.location.host;
  var pathArray = window.location.pathname.split("/");
  let prevJsUrl, prevCssUrl;

  /* ========= Modify Paligo exported html data url if directory level is greater than 4 ========== */
  if (pathArray.length > 6) {
    //console.log(pathArray.length);
    switch (pathArray.length) {
      case (pathArray.length = 7):
        prevJsUrl = "head script[src^='../../js/']";
        prevCssUrl = "head link[href^='../../css/']";
        break;

      case (pathArray.length = 8):
        prevJsUrl = "head script[src^='../../../js/']";
        prevCssUrl = "head link[href^='../../../css/']";
        break;

      case (pathArray.length = 9):
        prevJsUrl = "head script[src^='../../../../js/']";
        prevCssUrl = "head link[href^='../../../../css/']";
        break;

      case (pathArray.length = 10):
        prevJsUrl = "head script[src^='../../../../../js/']";
        prevCssUrl = "head link[href^='../../../../../css/']";
        break;

      case (pathArray.length = 11):
        prevJsUrl = "head script[src^='../../../../../../js/']";
        prevCssUrl = "head link[href^='../../../../../../css/']";
        break;

      case (pathArray.length = 12):
        prevJsUrl = "head script[src^='../../../../../../../js/']";
        prevCssUrl = "head link[href^='../../../../../../../css/']";
        break;

      default:
        break;
    }
    $(prevJsUrl).attr("src", function(i, oldJsSrc) {
      var splitJsSrc = oldJsSrc.split("/");
      var newJsSrc = "/js/" + splitJsSrc[splitJsSrc.length - 1];
      return newJsSrc;
    });

    $(prevCssUrl).attr("href", function(i, oldCssSrc) {
      var splitCssSrc = oldCssSrc.split("/");
      var newCssSrc = "/css/" + splitCssSrc[splitCssSrc.length - 1];
      return newCssSrc;
    });
  }

  /* ================= Modify Breadcrumb links =========================== */
  // var final_link = base_url + "/" + pathArray[1] + "/" + pathArray[2] + "/" + "index.html";
  var final_link = base_url + "/" + pathArray[2];

  // ===
  $(".breadcrumb:first").prepend(
    '<li class="breadcrumb-link"><a href="' +
      base_url +
      '">Exabeam Documentation</a></li>'
  );

  $(".breadcrumb-link:eq(1)")
    .children("a")
    .attr("href", final_link);

  var dd = $("#pdf-icon").detach();
  dd.appendTo(".publication-links");
});
