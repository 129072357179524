/* =============================================================================
JS common to all pages (navbar, scroll behaviour, etc.)
============================================================================= */

import easydropdown from "easydropdown";

jQuery(($) => {
  /* =================================================
    Flag navbar/title bar with "shrunk" once page is scrolled downwards.
    =================================================== */
  if (window.requestAnimationFrame) {
    const $body = $("body");
    const $publicationHeader = $(".publication-header");
    const getScrollTop = () => $(window).scrollTop();
    const onScroll = () => {
      const hideHeader = curScrollTop > 32;
      // Shrink navbar on this condition.
      $body.toggleClass("header--shrunk", hideHeader);

      // Only shrink title bar if present.
      if ($publicationHeader.length) {
        const hideTitle =
          curScrollTop >
          $publicationHeader.offset().top + $publicationHeader.height() - 70;
        // Shrink title bar on this condition.
        $body.toggleClass("title--shrunk", hideTitle);
      }
    };

    let curScrollTop;
    let lastScrollTop = getScrollTop();

    const rafLoop = () => {
      curScrollTop = getScrollTop();
      if (lastScrollTop === curScrollTop) {
        window.requestAnimationFrame(rafLoop);
        return;
      }

      // Fire onScroll function if window scrolls vertically.
      onScroll();

      lastScrollTop = curScrollTop;
      window.requestAnimationFrame(rafLoop);
    };

    rafLoop();
  }

  /* =================================================
    Derive .publication-header--scrolled from .publication-header
    for scrolled-down view of title bar
    =================================================== */
  // const $pubHeader = $( '.publication-header' );
  // if ( $pubHeader.length ) {
  //     const $pubHeaderScrolled = $( '<div class="publication-header--scrolled">' )
  //         .append( '<div class="container">' )
  //         .insertAfter( '.header__inner' )
  //         .find( '.container' );
  //     const $pubTitleClone = $pubHeader.find( '#publication-title' ).clone();
  //     $pubTitleClone.find( '> small' ).remove();
  //     const pubTitle = $pubTitleClone.text();
  //     const $pubLinksClone = $pubHeader.find( '.publication-links' ).clone();
  //     $pubLinksClone.find( '#version-dropdown' ).attr( 'id', 'version-dropdown-2' );
  //     $pubHeaderScrolled
  //         .append( $( '<h2>' ).text( pubTitle ) )
  //         .append( $pubLinksClone );
  // }

  /* =================================================
    Enable expansion of mobile navbar.
    =================================================== */
  const $header = $(".header");
  $(".header__navbar-toggler").on("click", function() {
    $header.toggleClass("header--expanded");
    $(this).attr("aria-expanded", $header.hasClass("header--expanded"));
    return false;
  });

  /* =================================================
    Handle expansion/collapse of submenus in mobile navbar.
    =================================================== */
  $(".header__mobile .menu-item-has-children > a").on("click", function() {
    const $thisMenu = $(this).siblings(".sub-menu");
    $(".header__mobile .sub-menu")
      .not($thisMenu)
      .removeClass("sub-menu--expanded");
    $thisMenu.toggleClass("sub-menu--expanded");
    return false;
  });

  /* =================================================
    Activate styled <select> dropdowns
    =================================================== */
  easydropdown.all();

  // Handle clicking of different versions in the version dropdown.
  $("#version-dropdown, #version-dropdown-2").on("change", (e) => {
    window.location.href = e.target.value;
  });

  /* =================================================
    Remove "prev" if on first topic of publication.
    =================================================== */
  // If first topic is active, then we are on the first page.
  if ($(".toc > li:first-child > a.active").length) {
    $("#header-navigation-prev").addClass("invisible");
  }
});
